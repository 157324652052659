<template>
  <div>
    <div class="container_wrap bottom_blank">
      <el-row type="flex" justify="center" align="center">
        <el-col :span="18"><h1>确认商品信息</h1></el-col>
        <el-col :span="6" style="padding: 34px;" >
          <div v-if="best_discount_loading">
            <i class="el-icon-loading" style="color: #67C23A"></i>
            <span style="margin-left: 10px;">正在匹配折扣 ... </span>
          </div>
        </el-col>
      </el-row>
      <el-table :data="order_goods_list" class="order_table_common"
                v-if="order_goods_list.length > 0">
        <el-table-column label="商品信息">
          <template #default="scope">
            <!-- 课程 -->
            <div class="course_item_common pointer" v-if="scope.row.type == 'curriculum'">
              <div class="cover" @click="goCurDetail(scope.row.cur_uuid)">
                <img :src="scope.row.cur_cover" />
                <span class="hot" v-if="scope.row.cur_label && scope.row.cur_label != 'null'">{{scope.row.cur_label}}</span>
                <span class="tag" v-if="scope.row.cur_type">{{ scope.row.cur_type }}</span>
              </div>
              <div class="content">
                <h4 @click="goCurDetail(scope.row.cur_uuid)">{{ scope.row.cur_name }}</h4>
                <div class="info">
                  章节：{{ scope.row.chapter_desc }}
                </div>
              </div>
            </div>
            <!-- 会员卡 -->
            <div  v-if="scope.row.type == 'vip'">
              <VipCard :vip_card="scope.row.goods_info"
                       :expired_at="scope.row.goods_info.expired_detail_text"
                       :price_visible="false" :btn_buy="false" :btn_detail="false"></VipCard>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="价格" width="150">
          <template #default="scope">
            ¥ {{ scope.row.price }}
          </template>
        </el-table-column>
        <el-table-column label="优惠" width="250">
          <template #default="scope" >
            <div v-if="scope.row.promotes_count > 0">
              <div v-if="scope.row.promotes.length > 0">
                <div style="color:#f51c21;">-¥ {{ scope.row.discount_amount }}</div>
                <div> <span>{{scope.row.discount_desc}}</span>
                  <el-button type="text" size="mini" @click="showDiscountModal(scope.row)">重选</el-button>
                </div>
                <div v-if="scope.row.expired_days">学习有效期：{{scope.row.expired_days}}天</div>
              </div>
              <div v-else>
                <el-button type="text" size="mini" @click="showDiscountModal(scope.row)">选择可用优惠</el-button>
              </div>
            </div>
            <div v-else> - </div>
          </template>
        </el-table-column>
        <el-table-column label="实付" width="150">
          <template #default="scope">
            <div style="color:#f51c21;">¥ {{ scope.row.amount }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <transition name="el-fade-in-linear">
      <div class="settle_wrap" v-show="isShow">
        <div class="container_wrap">
          共 {{ order_goods_list.length }} 个商品，商品金额：¥ {{total_amount}}，
          优惠金额：¥ {{discount_amount}}，
          实付金额：<span class="price">¥{{order_amount}}</span>

          <el-button type="danger" @click="submitOrder" round v-if="order_goods_list.length > 0">
            <span v-if="order_amount > 0">提交订单</span>
            <span v-else>完成订单</span>
          </el-button>
        </div>
      </div>
    </transition>

    <!-- 选择优惠 -->
    <el-dialog title="选择优惠方式" v-model="discountModal.visible" width="1000px">

      <el-row :gutter="50">
        <el-col :span="13">
          <!-- 切换 -->
          <el-radio-group v-model="discountModal.tabActiveName" class="radio_tabs" size="medium">
            <el-radio-button label="able">可用</el-radio-button>
            <el-radio-button label="disable">不可用</el-radio-button>
          </el-radio-group>
        </el-col>
        <el-col :span="11">
          <el-form :inline="true" :model="discountModal.codeForm"
                   :rules="rules" ref="couponsExchangeFrm"
                   size="small" label-width="70px" style="display:flex;">
            <el-form-item label="兑换码" prop="exchangeCode">
              <el-input placeholder="请输入优惠券兑换码" v-model="discountModal.codeForm.exchangeCode"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="exchangeCouponsUser">领取</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <h4 class="modal_title">会员卡</h4>
      <el-scrollbar max-height="400px" v-loading="discountModal.loading"
                    view-style="overflow-x:hidden;">
        <el-row :gutter="20">

          <el-col :span="12" v-for="vip_card in discountModal.vip_users">

            <el-radio  v-model="discountModal.radio"
                       @change="notifyPromotesRadioChange(vip_card)"
                       :disabled="discountModal.tabActiveName=='disable'"
                       :label="`vip#${vip_card.promotes_uuid}`"
                       border class="discount_radio">

              <VipCard :vip_card="vip_card"
                       :expired_text_color="vip_card.expired_text_color"
                       :price_visible="false" :btn_buy="false"
                       :btn_detail="false" :size_small="true"
                       :expired_at="vip_card.expired_at"></VipCard>
              <div style="margin-top: 10px"
                   v-if="discountModal.tabActiveName=='disable'">
                <span>不可用原因: {{vip_card.disabled_reason}}</span>
              </div>
              <el-row :gutter="20" v-if="discountModal.tabActiveName=='able'" style="margin-top: 10px;">
                <el-col :span="7">优惠金额：<span style="color: rgb(245, 28, 33);">¥ {{vip_card.discount_amount}}</span></el-col>
                <el-col v-if="vip_card.type == 'free'" :span="13">学习有效期：{{vip_card.expired_days}} 天 <span style="color: #C0C4CC;">（为会员卡的剩余可用天数）</span></el-col>
                <el-col v-else :span="13"><br></el-col>
              </el-row>
            </el-radio>
          </el-col>
        </el-row>
      </el-scrollbar>
      <h4 class="modal_title">优惠券</h4>
      <el-scrollbar max-height="400px" v-loading="discountModal.loading"
                    view-style="overflow-x:hidden;">
        <el-row :gutter="20">

          <el-col :span="12"  v-for="coupons in discountModal.coupons_users">
            <el-radio v-model="discountModal.radio"
                      @change="notifyPromotesRadioChange(coupons)"
                      :disabled="discountModal.tabActiveName=='disable'"
                      :label="`coupons#${coupons.promotes_uuid}`"
                      border class="discount_radio">

              <Coupons :coupons="coupons"
                       :expired_text_color="coupons.expired_text_color"
                       :price_visible="false" :btn_issue="false"
                       :user_limit_visible="false"
                       :btn_detail="false" :size_small="true"
                       :expired_at="coupons.expired_at"></Coupons>

              <div style="margin-top: 10px"
                   v-if="discountModal.tabActiveName=='disable'">
                <span>不可用原因:  {{coupons.disabled_reason}}</span>
              </div>

              <el-row v-if="discountModal.tabActiveName=='able'" style="margin-top: 10px;">
                <el-col>优惠金额：<span style="color: rgb(245, 28, 33);">¥ {{coupons.discount_amount}}</span></el-col>
              </el-row>

            </el-radio>
          </el-col>

        </el-row>
      </el-scrollbar>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="text"
                     :class="{'refuse_btn':true, 'active':!discountModal.noDiscount}"
                     @click="clearDiscount">不使用优惠</el-button>
          <el-button @click="cancelCurrentPromotes">取消</el-button>
          <el-button type="primary"
                     @click="confirmCurrentPromotes">确定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
  import VipCard from "components/settlement/VipCard"
  import Coupons from "components/settlement/Coupons"
  import {fmtVIPCardInfo} from 'api/settlement/vip_card/admin'
  import {fmtCouponsInfo} from 'api/settlement/coupons/admin'
  import {exchangeCoupons } from 'api/settlement/coupons/user'
  import {confirmOrder, listPromotes, submitOrder, confirmListPromotes} from 'api/settlement/order/user'
  import { selectBestDiscount, get_goods_discount_amount } from 'api/settlement/order/discount'
  import {fmtCartItemInfo} from 'api/settlement/cart/user'
  import {expired_delta_days_conf} from 'api/settlement/card_util'
  import { db_time_to_local_fmt, delta_days_text } from 'utils/timeutils'
  import { getCookie } from 'api/base/axios'
  import { toRefs } from 'vue'
  import { ElLoading,ElMessage } from 'element-plus';
  import { showMessage } from 'api/base/message'
  export default {
    components: {
      VipCard,
      Coupons
    },
    data() {
      return {
        best_discount_loading: false,
        confirm_key: null,
        isShow: false,
        order_goods_list: [],
        total_amount: 0,
        discount_amount: 0,
        order_amount: 0,
        discountModal: {
          loading: false,
          visible: false,
          res_type: 'curriculum',
          res_uuid: null,
          tabActiveName: 'able',
          codeForm: {
            exchangeCode: ''
          },
          radio: '',
          noDiscount: true,
          current_goods_price: 0,
          current_promotes: null,
          ref_row: {
              discount_amount: null
          },
          vip_users: [],
          coupons_users: [],
        },
        rules: {
          exchangeCode: [
            {required: true, message: '优惠券兑换码不能为空', trigger: 'change'},
            {min: 12, max: 20, message: '长度在 12~20 个字符内', trigger: 'change'},
          ],
        }
      }
    },
    created() {
      if (this.$route.params.confirm_key != undefined) {
        this.confirm_key = this.$route.params.confirm_key;
        this.loadConfirmOrderData();
      }

      this.$watch(() => this.discountModal.radio, (newVal, oldVal) => {

          if (newVal !== '') {

            this.discountModal.noDiscount = false;

          } else {

            this.discountModal.noDiscount = true;
          }

      });

      this.$watch(() => this.discountModal.tabActiveName, (newVal, oldVal) => {

          this.loadCurPromotes(this.discountModal.res_uuid);

      });

      this.$watch(() => this.discountModal.ref_row.discount_amount, (newVal, oldVal) => {
          this.notifyGoodsDiscouontTotal();
          this.discountModal.ref_row.amount = parseFloat((
              this.discountModal.ref_row.price - this.discountModal.ref_row.discount_amount).toFixed(2));
      });

      this.$watch(() => this.discountModal.visible, (newVal, oldVal) => {
        if (!newVal) {
          this.$refs.couponsExchangeFrm.resetFields();
        }
      });


    },
    mounted() {},
    methods: {
      async loadConfirmOrderData(){

          const that = this;

          await confirmOrder(this.confirm_key).then(result=>{

            if (result.succeed) {

                let goods_list = [];

                let total_amount = 0;

                let have_goods_promotes = false;

                result.order_goods.forEach(item=>{

                    let filled_item = fmtCartItemInfo(item);
                    filled_item.promotes = [];
                    filled_item.discount_desc = '';
                    filled_item.discount_amount = 0;
                    filled_item.amount = filled_item.price;

                    goods_list.push(filled_item);

                    total_amount += filled_item.price;

                    if(item.type === "curriculum" && item.promotes_count > 0){
                        have_goods_promotes = true;
                    }

                });

                total_amount = parseFloat(total_amount.toFixed(2));

                that.order_goods_list = goods_list;
                that.total_amount = total_amount;
                that.order_amount = total_amount;

                // 如果商品中有课程,加载所有可用优惠,匹配最优解
                if (have_goods_promotes){
                  that.best_discount_loading = true;
                  var request_begin = new Date().getTime();
                  console.debug('[Request] Loading all promotes start ...');
                  confirmListPromotes(this.confirm_key).then(promotes_result => {

                      var request_end = new Date().getTime();
                      console.debug(`[Request] Loading all promotes ended, pending ${request_end - request_begin} ms`);

                    if (promotes_result.succeed) {

                        promotes_result.order_goods.forEach((item, index)=>{
                            item.goods_info = result.order_goods[index].goods_info;
                        });

                        let best_discount_dict = selectBestDiscount(promotes_result.order_goods);

                        console.log(best_discount_dict);

                        var match_end = new Date().getTime();
                        console.debug(`[Total] in ${match_end - request_begin} ms`);

                        that.order_goods_list.forEach((goods_item)=>{

                            switch(goods_item.type){
                              case "curriculum": {

                                  if (goods_item.res_uuid in best_discount_dict['cur']){

                                      let cur_promotes = best_discount_dict['cur'][goods_item.res_uuid];

                                    if (cur_promotes.discount_type && cur_promotes.discount_uuid) {

                                        goods_item.promotes = [{
                                          type: cur_promotes.discount_type,
                                          uuid: cur_promotes.discount_uuid
                                        }];

                                        goods_item.discount_desc = cur_promotes.max_discount_desc;
                                        goods_item.discount_amount = parseFloat(cur_promotes.max_discount_amount.toFixed(2));
                                        goods_item.amount = parseFloat((goods_item.amount - cur_promotes.max_discount_amount).toFixed(2));

                                        if (cur_promotes.discount_type === 'vip'
                                          && cur_promotes.promotes_item.vip_card.type === 'free') {

                                            goods_item.expired_days = Math.ceil(delta_days_text(cur_promotes.promotes_item.rights_end));

                                        }
                                    }

                                  }

                              } break;
                              case "vip": {
                              } break;
                            }

                        });

                        this.notifyGoodsDiscouontTotal();

                        if (that.discount_amount > 0) {
                          ElMessage({
                            showClose: true,
                            message: `系统已经为您自动选择折扣,优惠${that.discount_amount}元`,
                            type: 'success',
                            offset: 70
                          });
                        }

                    } else {
                      this.$alert(`${result.error_msg}`, '提示', {
                        confirmButtonText: '确定'
                      });
                    }

                    that.best_discount_loading = false;

                  }).catch(err => {
                    this.$alert(`${err}`, '提示', {
                      confirmButtonText: '确定'
                    });
                  });
                }

                setTimeout(()=>{
                  that.isShow = true;
                }, 200);


            } else {
              this.$alert(`${result.error_msg}`, '提示', {
                confirmButtonText: '确定',
                callback: action => {
                  that.goPath('/course/list');
                }
              });
            }

          }).catch(err=>{
            this.$alert(`${err}`, '提示', {
              confirmButtonText: '确定'
            });
          });
      },
      notifyGoodsDiscouontTotal(){
        let total_discount_amount = 0;
        this.order_goods_list.forEach(item => {
          total_discount_amount += item.discount_amount;
        });
        this.discount_amount = parseFloat(total_discount_amount.toFixed(2));
      },
      selectChange(val) {
        this.selectedRows = val;
      },
      clearDiscount() {
        this.discountModal.radio = '';
      },
      showDiscountModal(row){

          this.discountModal.ref_row = toRefs(row);

          if (row.promotes.length > 0){
              let promote_item = row.promotes[0];
              this.discountModal.radio = `${promote_item.type}#${promote_item.uuid}`;
              this.discountModal.noDiscount = false;
          } else {
              this.discountModal.noDiscount = true;
              this.discountModal.radio = '';
          }

          if(row.type == 'curriculum'){
              this.discountModal.tabActiveName = 'able';
              this.loadCurPromotes(row.cur_uuid);
          }

      },
      loadCurPromotes(cur_uuid){

        const that = this;

        this.discountModal.loading = true;
        this.discountModal.res_uuid = cur_uuid;
        this.discountModal.res_type = 'curriculum';

        let query_filter = {
          'goods_type': 'curriculum',
          'goods_uuid': cur_uuid,
          'mode': this.discountModal.tabActiveName,
          'exclude_promotes': this.getOtherSelectedPromotes(),
        };

        listPromotes(query_filter).then(result => {

            if(result.succeed){

              that.discountModal.vip_users = [];
              that.discountModal.coupons_users = [];

              let match_vip = false;
              let match_coupons = false;
              let promotes_uuid = null;

              if(this.discountModal.radio!==''){

                  let split_arr = this.discountModal.radio.split('#')
                  let promotes_type = split_arr[0];
                  promotes_uuid = split_arr[1];

                if (promotes_type === 'vip') {
                    match_vip = true;
                } else if (promotes_type === 'coupons') {
                    match_coupons = true;
                }

              }

              result.vips.forEach(item => {

                console.log('item->', item);

                item.vip_card.single_show = true;

                let filled_item = fmtVIPCardInfo(item.vip_card);
                filled_item.promote_type = 'vip_card';
                filled_item.rights_start_local = db_time_to_local_fmt(item.rights_start);
                filled_item.expired_at = db_time_to_local_fmt(item.rights_end, 'yyyy-MM-dd');
                filled_item.expired_at_origin = item.rights_end;
                filled_item.failed_code = item.failed_code;
                filled_item.disabled_reason = item.failed_reason;
                filled_item.promotes_uuid = item.uuid;
                filled_item.discount_amount = get_goods_discount_amount(this.discountModal.ref_row.price, 'vip', item).discount_amount;

                console.log('vips', item.failed_code , item.failed_reason);

                let delta_conf = expired_delta_days_conf(item.rights_end);
                filled_item.expired_days = delta_conf.delta_days;
                filled_item.expired_text = delta_conf.delta_text;
                filled_item.expired_text_color = delta_conf.text_color;

                that.discountModal.vip_users.push(filled_item);

                if (match_vip && promotes_uuid === filled_item.promotes_uuid){
                    this.discountModal.current_promotes = filled_item;
                }

              });

              result.coupons.forEach(item => {

                item.coupons.single_show = true;

                let filled_item = fmtCouponsInfo(item.coupons);
                filled_item.promote_type = 'coupons';
                filled_item.time_start_local = db_time_to_local_fmt(item.time_start);
                filled_item.expired_at = db_time_to_local_fmt(item.time_end, 'yyyy-MM-dd');
                filled_item.expired_at_origin = item.time_end;
                filled_item.failed_code = item.failed_code;
                filled_item.disabled_reason = item.failed_reason;
                filled_item.discount_amount = get_goods_discount_amount(this.discountModal.ref_row.price, 'coupons', item).discount_amount;

                console.log('coupons', item.failed_code , item.failed_reason);

                filled_item.promotes_uuid = item.uuid;

                let delta_conf = expired_delta_days_conf(item.time_end);
                filled_item.expired_days = delta_conf.delta_days;
                filled_item.expired_text = delta_conf.delta_text;
                filled_item.expired_text_color = delta_conf.text_color;

                that.discountModal.coupons_users.push(filled_item);

                if (match_coupons && promotes_uuid === filled_item.promotes_uuid){
                    this.discountModal.current_promotes = filled_item;
                }

              });

              this.discountModal.visible = true;

            }else{
              this.$alert(`${result.error_msg}`, '提示', {
                confirmButtonText: '确定'
              });
            }

            this.discountModal.loading = false;

        }).catch(err => {
          this.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });
      },
      getOtherSelectedPromotes(){

        let current_coupons_user_uuids = [];
        let current_vip_user_uuids = [];
        let current_promotes = this.discountModal.ref_row.promotes;
        current_promotes.forEach(item => {
          switch (item.type) {
            case "coupons": {
              current_coupons_user_uuids.push(item.uuid);
            }
              break;
            case "vip": {
              current_vip_user_uuids.push(item.uuid);
            }
              break;
          }

        });


        let selected_coupons_user_uuids = [];
        let selected_vip_user_uuids = [];
        this.order_goods_list.forEach(item => {

          let promotes = JSON.parse(JSON.stringify(item.promotes));

          promotes.forEach(item => {

            switch (item.type) {
              case "coupons": {
                if (!current_coupons_user_uuids.includes(item.uuid)) {
                  selected_coupons_user_uuids.push(item.uuid);
                }

              } break;
              case "vip": {
                if (!current_vip_user_uuids.includes(item.uuid)) {
                  selected_vip_user_uuids.push(item.uuid);
                }

              } break;
            }

          });

        });

        return {
          coupons: selected_coupons_user_uuids,
          vips: []
//          vips: selected_vip_user_uuids
        }

      },
      async confirmCurrentPromotes(){

        if (this.discountModal.radio !== '') {

          let promotes = this.discountModal.radio.split('#');

          this.discountModal.ref_row.promotes = [{
            type: promotes[0],
            uuid: promotes[1]
          }];
          console.log('current_promotes', this.discountModal.current_promotes);


          if (this.discountModal.current_promotes) {


            let goods_discount = get_goods_discount_amount(
              this.discountModal.ref_row.price,
              promotes[0],
              this.discountModal.current_promotes);

            this.discountModal.ref_row.discount_amount = parseFloat(goods_discount.discount_amount.toFixed(2));
            this.discountModal.ref_row.amount = parseFloat(goods_discount.goods_amount.toFixed(2));

            let expired_days = null;

            if (this.discountModal.current_promotes.promote_type === 'vip_card'
              && this.discountModal.current_promotes.vip_type === 'free'){
                 expired_days = Math.ceil(delta_days_text(this.discountModal.current_promotes.expired_at_origin));
            }

            this.discountModal.ref_row.expired_days = expired_days;
            this.discountModal.ref_row.discount_desc = goods_discount.discount_desc;

          } else {
            console.log('autoload item ,skipped')
          }


        } else {
            this.discountModal.ref_row.promotes = [];
            this.discountModal.ref_row.discount_amount = 0;
            this.discountModal.ref_row.expired_days = null;
        }

        this.discountModal.visible = false;
        this.$refs.couponsExchangeFrm.resetFields();

      },
      cancelCurrentPromotes(){
          this.discountModal.visible = false;
          this.$refs.couponsExchangeFrm.resetFields();
      },
      submitOrder(){

        let post_data = {
            'confirm_key': this.confirm_key,
            'order_goods': [],
            'seller_code': getCookie('promo_code'),
            'order_amount': this.order_amount
        };

        this.order_goods_list.forEach(item => {

          post_data['order_goods'].push({
            type: item.type,
            res_uuid: item.res_uuid,
            promotes: JSON.parse(JSON.stringify(item.promotes))
          });

        });

        let loadingInstance = ElLoading.service({
          fullscreen: true ,
          text: '正在提交订单, 请稍后 ...'
        });

        let that = this;
        submitOrder(post_data).then(result => {

          if (result.succeed) {

              let order_info = result.instance.order_info;
              that.goPath(`/order/pay/${order_info.uuid}`);
              loadingInstance.close();

              if(result.price_change){
                  showMessage('订单商品价格已经发生改变，系统已经重新为您计算价格')
              }

              that.$emit('update:cartItemCount', result.cart_item_count);

          } else {
              loadingInstance.close();
              this.$alert(`${result.error_msg}`, '提示', {
                confirmButtonText: '确定',
                callback: action => {
                  that.goPath('/course/list');
                }
              });
          }

        }).catch(err => {
          loadingInstance.close();
          this.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });

      },
      goCurDetail(cur_uuid){
        const cur_detail_page = this.$router.resolve({name: 'CourseDetail', params: {uuid: cur_uuid}});
        window.open(cur_detail_page.href, '_blank');
      },
      exchangeCouponsUser(){

        const that = this;

        this.$refs['couponsExchangeFrm'].validate((valid) => {

          if (valid) {

            exchangeCoupons(this.discountModal.codeForm.exchangeCode).then(result => {

                if (result.succeed) {

                  that.$notify({
                    title: '兑换成功',
                    message: '优惠券已放入卡包~',
                    type: 'success'
                  });

                  if (that.discountModal.tabActiveName != 'able') {
                    that.discountModal.tabActiveName = 'able';
                  }

                  if (this.discountModal.ref_row.type == 'curriculum') {
                    that.loadCurPromotes(this.discountModal.ref_row.cur_uuid);
                  }

                } else {
                  that.$notify({
                    title: '提示',
                    message: result.error_msg,
                    type: 'warning'
                  });
                }

              }).catch(err => {
              that.$notify({
                title: '提示',
                message: err,
                type: 'warning'
              });
            });

          } else {
            console.log('error submit!!');
            return false;
          }

        });
      },
      notifyPromotesRadioChange(promotes){
          this.discountModal.current_promotes = promotes;
      },
      goPath(path) {
        this.$router.push(path);
      },
    },
    watch: {
      discount_amount(val) {
          this.discount_amount = val;
          this.order_amount = parseFloat((this.total_amount - this.discount_amount).toFixed(2));
      }
    }
  }
</script>

<style lang="scss" scoped>
  h1 {
    font-size:30px;
    color:#333;
    margin:0;
    font-weight: normal;
    padding:20px 0 30px 0;
  }
  .bottom_blank {
    padding-bottom:60px;
  }
  .course_item_common {
    &.disabled {
      .content {
        > h4, .info {
          color:#999;
        }
      }
    }
  }
  .settle_wrap {
    padding-top:20px;
    padding-bottom:20px;
    text-align:right;
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    background:#fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    z-index:1;
    span.price {
      margin-left:15px;
      font-size:42px;
      color:#f51c21;
      font-family: 'font-540';
      vertical-align: middle;
    }
    .el-button {
      font-size:16px;
      padding:15px 50px;
      border-radius:100px;
      margin-left:60px;
    }
  }
  :deep(.vipcard_item), :deep(.coupon_item) {
    line-height: normal;
    margin:0;
    max-width:450px;
    &.sm {
      margin-bottom:0;
    }
  }
  :deep(.discount_radio) {
    display:flex;
    height:auto;
    padding-bottom:12px;
    margin-bottom:20px;
    .el-radio__input {
      display: flex;
      align-items: center;
    }
    .el-radio__label {
      flex:1;
    }
  }
  .refuse_btn {
    font-size:12px;
    color:#999;
    &:hover {
      color:#666;
    }
    &.active {
      color:#00adef;
    }
  }
  :deep(.el-radio__input.is-checked + .el-radio__label) {
    color:#666;
  }
  .modal_title {
    margin-top:20px;
  }
</style>

